import React, { useState } from 'react'
import AgoraRTM from 'agora-rtm-sdk'
import { useEffect } from 'react'
import { BASEURL, headers, AGORA_APP_ID } from '../utils/constant'
import axios from 'axios';
import RtmClient from './rts-client'; 


  const rtm = new RtmClient()

  
   
const JoinChannel = () =>{

const [token, setToken] = useState('')
const[channelName, setChannelName] = useState("ORG-PAT-436")
const [uid, setUid] = useState("1000450")
useEffect(async()=>{
    const parameter={
       channelName:channelName,
         uid:uid
    }
    console.log(parameter);
  await axios.post(BASEURL + 'api/caremeapp/agora_rtctoken/', parameter, {headers})
   .then((response)=>{
      console.log(response.data.rtm_data);
      setToken(response.data.rtm_data)
   })
   
   // this.rtm.init("99499b61fbef4cdd955aabd0bcd529fe")
   //  window.rtm = rtm
   // this.rtm.login(uid, token).then(() => {
   //              console.log('login')
   //              this.rtm._logined = true
   // })
   //      this.rtm.joinChannel(channelName).then(() => {
   //            rtm.channels[channelName].joined = true
   //            console.log("joined channel successfully");
   //     }).catch((err) => {
   //   console.log(err)
    
   // })

      
},[])

   return(
<div></div>
   ) 
}

export default JoinChannel;