import React from "react";
import { merge } from "lodash";
import AgoraRTC from "agora-rtc-sdk";
// import { Collapse, Button, CardBody, Card, Spinner } from 'reactstrap';
import ScrollableFeed from "react-scrollable-feed";
import "./canvas.css";
import axios from "axios";

import AgoraRTM from "agora-rtm-sdk";
import RtmClient from "./Agora/rtm-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { XCircle, Send } from "react-feather";
import SendSms from "./SendSms";
import ReceiveMsg from "./ReceiveMsg";
import { BASEURL, AGORA_APP_ID } from "./utils/constant";
import { createRef } from "react";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import { CloudDownloadOutline, AttachOutline, RefreshCircleSharp, RefreshOutline, ArrowDown, ChevronDown } from "react-ionicons";
import Modal from "@material-ui/core/Modal";
import alertSount from "../assets/images/alert.mp3";
import { FileDrop } from "react-file-drop";
import { Flash } from "react-ionicons";
import Button from "@material-ui/core/Button";
import { BsCheckAll } from "react-icons/bs";
import { LensOutlined } from "@material-ui/icons";

// import {
//   Toast,
//   validator,
//   serializeFormData,
//   imageToBlob,
//   blobToImage
// } from '../AgoraChat/common'

const tile_canvas = {
  1: ["span 12/span 24"],
  2: ["span 12/span 12/13/25", "span 12/span 12/13/13"],
  3: ["span 6/span 12", "span 6/span 12", "span 6/span 12/7/19"],
  4: [
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12/7/13",
  ],
  5: [
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 9/span 16/10/21",
  ],
  6: [
    "span 3/span 4/13/7",
    "span 3/span 4/13/11",
    "span 3/span 4/13/15",
    "span 3/span 4/13/19",
    "span 3/span 4/13/23",
    "span 9/span 16/10/21",
  ],
  7: [
    "span 3/span 4/13/5",
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 3/span 4/13/25",
    "span 9/span 16/10/21",
  ],
};
const headers = {
  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
  "Content-Type": "application/json",
};
const uid_key = (Math.floor(Math.random() * 10000) + 10000)
  .toString()
  .substring(1);
// console.log(uid_key)
// const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });
// Query the container to which the remote stream belong.

class AgoraCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.client = {};
    this.messagesEndRef = React.createRef();
    this.localStream = {};
    this.muteTime = 0;
    this.channel = localStorage.channelid;
    this.appId = AGORA_APP_ID;
    this.user = "doctor";
    this.uid = uid_key;
    this.appointment_n_key = "CAR1-APT-2067";
    this.org_n_key = "ORGID-1";
    this.patient_n_key = localStorage.patient_n_key;
    this.state = {
      devices: [],
      displayMode: "pip",
      streamList: [],
      readyState: false,
      doctorMuteTime: 0,
      patientMuteTime: 0,
      doctorStartTime: null,
      doctorEndTime: null,
      patientStartTime: null,
      patientEndTime: null,
      showVideo: true,
      showAudio: true,
      isOpen: false,
      volumeopen: false,
      errorMsg: "",
      interval: "",
      docPatmsg: [],
      loading: false,
      url: "",
      appKey: "",
      user: "",
      addResponse: false,
      userJoined: false,
      minScreen: false,
      chatOpen: false,
      selectedFile: null,
      open: false,
      disable: true,
      pt_name: "",
      page:0,
      total_page:0,
      total_items:0,
    };
    this.rtm = new RtmClient();
    this.callbackUser = this.callbackUser.bind(this);
  }

  openChatScreen = () => {
    this.setState({ chatOpen: !this.state.chatOpen });
  };

  componentWillMount() {
    this.getName();
    setInterval(() => {
      this.getChatHistory();
    }, 1000);
    let $ = this;

    const parameter = {
      channelName: localStorage.channelid,
      uid: "" + $.uid,
    };

    // console.log("params", parameter);
    axios
      .post(BASEURL + "api/caremeapp/agora_rtctoken/", parameter, { headers })
      .then((response) => {
        // console.log("response token data", response.data);
        this.setState(
          { rtcToken: response.data.data, rtmToken: response.data.rtm_data },
          () => {
            // console.log(this.state.rtcToken, this.state.rtmToken, "Tokens");
          }
        );
        // console.log("agora log", $);
        //RTM Chat
        try {
          this.rtm.init($.appId);
          this.rtm
            .login($.uid, this.state.rtmToken)
            .then(() => {
              console.log("login");
              this.rtm._logined = true;
              //Toast.notice('Login: ' + $.uid, ' token: ', nil)
              this.rtm
                .joinChannel(localStorage.channelid)
                .then(() => {
                  console.log("RTM Message join successfully");
                  this.rtm.channels[localStorage.channelid].joined = true;
                  this.getChatHistory();
                  this.subscribeMessageEvents();
                  this.openChatScreen();
                })
                .catch((err) => {
                  console.error(err);
                });
            })
            .catch((err) => {
              console.log("login failed");
              console.log(err);
            });
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        toast("Join channel failed, please open console see more details.");
        //Toast.error('Join channel failed, please open console see more details.')
        console.error(err);
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  getName = () => {
    axios
      .post(
        BASEURL + "api/caremeapp/patientedit_app/",
        { patient_n_key: localStorage.patient_n_key },
        { headers }
      )
      .then((res) => {
        if (res.data.status == "success") {
          this.setState({ pt_name: res.data.data.first_name });
        }
      });
  };
  componentDidMount() {
    localStorage.v__ = 1
    localStorage.vv__=1
    this.scrollToBottom();
    this.getChatHistory();
    this.getName();
    setInterval(() => {
      this.getChatHistory();
    }, 2000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      localStorage.vv__=this.state.page;
      console.log('page changed')
    }
  }

  componentWillReceiveProps() {
    console.log('props',this.props);
    if (this.props.data) {
      this.setState({ docPatmsg: [] });
      this.getChatHistory();
      this.getName()
    }
  }

  componentWillUnmount() {
    this.callbackUser(true);
    this.rtm
      .leaveChannel(this.channel)
      .then(() => {
        if (this.rtm.channels[this.channel]) {
          this.rtm.channels[this.channel].joined = false;
          this.rtm.channels[this.channel] = null;
        }
      })
      .catch((err) => {
        toast("Leave channel failed, please open console see more details.");
        console.error(err);
      });
  }
  callbackUser(value) {
    console.log("call back value", value);
    if (value == true) {
      this.setState({ userJoined: true });
      console.log("call back true");
    } else {
      this.setState({ userJoined: false });
    }
  }
  start = () => {
    new Audio(alertSount).play();
  };
  onFileChange = (event) => {
    // Update the state
    console.log(event);
    this.setState({
      open: true,
      selectedFile: event.target.files[0],
      disable: false,
    });
    console.log(this.state.open);
    console.log(this.state.selectedFile);
  };
  onDragChange = (files, event) => {
    // Update the state
    console.log(event);
    this.setState({ open: true, selectedFile: files[0], disable: false });
    console.log(this.state.open);
    console.log(this.state.selectedFile);
  };
  onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("document_attachment", this.state.selectedFile);
    formData.append("document_name", "chat");
    formData.append("doc_type", "Chat Documents");
    formData.append("patient_n_key", localStorage.getItem("patient_n_key"));
    console.log("formdata", formData);

    // Details of the uploaded file
    console.log(this.state.selectedFile);

    // Request made to the backend api
    // Send formData object
    axios
      .post(BASEURL + "api/caremeapp/appdocument_post/", formData, { headers })
      .then((response) => {
        console.log("url", response);
        this.setState({
          selectedFile: null,
          open: false,
          disable: true,
          loading: false,
          url: response.data.data,
        });
        this.rtm
          .sendChannelMessage(this.state.url, this.channel)
          .then(() => {
            const postfile_data = {
              id: this.props.empKey,
              channelid: localStorage.channelid,
              message: this.state.url,
              isSeen: "False",
              patient_n_key: localStorage.patient_n_key,
              hospital_n_key: "CAR-HOS-1",
              org_n_key: "ORGID-1",
              msg_type: "File",
            };

            console.log(postfile_data);
            axios
              .post(BASEURL + "api/chat/send_message/", postfile_data, { headers })
              .then((response) => {
                this.setState({
                  loading: false,
                  open: false,
                  disable: true,
                });
                this.getChatHistory();
                this.addLess()
                console.log(
                  "Post Message metrics",
                  "response get details:",
                  response.data
                );
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                });
                toast("Post Chat API error" + error);
                console.log(" Post Message metrics axios error:", error);
              });
            window.scrollTo({
              top: document.body.scrollHeight,
              left: 0,
              behavior: "smooth",
            });
          })
          .catch((err) => {
            toast("Send message failed, please open console see more details.");
            //Toast.error('Send message to channel ' + params.channelName + ' failed, please open console see more details.')
            console.error(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  popClose = () => {
    this.setState({
      open: false,
      selectedFile: null,
      disable: true,
    });
    console.log(this.state.open);
    console.log(this.state.selectedFile);
  };
  fileData = () => {
    // console.log(this.state.selectedFile)
    if (this.state.selectedFile) {
      return (
        <Modal
          open={this.state.open}
          onClose={this.popClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="file-details-pop">
            <div className="file-details-w">
              <h2>File Details:</h2>
              <p>File Name: {this.state.selectedFile.name}</p>
              <div>
                <button
                  className="btn-file bg-color2d"
                  onClick={this.onFileUpload}
                >
                  Upload
                </button>
                <button className="btn-file" onClick={this.popClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return <div></div>;
    }
  };
  postChatAPI = (message) => {
    this.setState({
      loading: false,
    });
    const data =
      this.user == "doctor"
        ? {
            id: this.props.empKey,
            channelid: localStorage.channelid,
            message: message,
            isSeen: "False",
            patient_n_key: localStorage.patient_n_key,
            hospital_n_key: "CAR-HOS-1",
            org_n_key: "ORGID-1",
            msg_type: "Text",
          }
        : {
            id: localStorage.patient_n_key,
            channelid: localStorage.channelid,
            message: message,
            isSeen: "False",
            patient_n_key: localStorage.patient_n_key,
            employee_n_key: null,
            hospital_n_key: "CAR-HOS-1",
            org_n_key: "ORGID-1",
            msg_type: "Text",
          };
    console.log(data);
    axios
      .post(BASEURL + "api/chat/send_message/", data, { headers })
      .then((response) => {
        this.setState({
          loading: false,
        });
        if(response.data.status==='success'){
          this.getChatHistory();
          this.addLess()
          this.setState({
            chattext: "",
          },()=>{
            this.scrollToBottom();
          });
        }
 
        console.log(
          "Post Message metrics",
          "response get details:",
          response
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        toast("Post Chat API error" + error);
        console.log(" Post Message metrics axios error:", error);
      });
  };
  saveResponse = () => {
    const data = {
      employee_n_key: this.props.empKey,
    };

    console.log(data);
    axios
      .post(BASEURL + "api/chat/send_message/", data, { headers })
      .then((response) => {
        console.log(response);
      });
  };
  getChatHistory = () => {
    const scrollY = window.scrollY; 
    this.setState({
      loading: false,
    });
    const pValue = parseInt(localStorage.v__)
    const ppValue = parseInt(localStorage.vv__)
    console.log(pValue,ppValue)
    axios
      .post(
        BASEURL + "api/chat/view_chat/",
        {
          id: localStorage.employee_n_key,
          channelid: localStorage.channelid,
          page:parseInt(localStorage.v__)||1,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
        }
      )
      .then((response) => {
        if (!isNaN(pValue)) {
          this.setState(prevState =>{
            if (pValue === 1) {
              console.log('a')
             return {docPatmsg: this.groupByDates(response.data.chat_history)};
            } else if (pValue === ppValue) {
              console.log('b')
             return {docPatmsg:prevState.docPatmsg}
            } else {
              console.log('c')
              let dat_ = [...prevState.docPatmsg];
                let mergedDetails = dat_.flatMap(item => item.details);
                let uniqueDetails = [...new Set(mergedDetails)];
                let mergedData = [...response.data.chat_history, ...uniqueDetails];
                let groupedData = this.groupByDates(mergedData);
                console.log('cls',groupedData)
              return {docPatmsg:groupedData};
            }
          });
        }
        else {
          console.error("localStorage.p__ is not a valid integer.");
        } 
          
         this.setState({
          loading: false,
          page:response.data.current_page,
          total_page:response.data.total_pages,
          total_items:response.data.total_items,
         })
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        toast("Get Chat History API error" + error);
      });
  };

  openPopUp = () => {
    this.setState({
      addResponse: false,
    });
    let popup = document.getElementById("careC-popup-wrapper");
    let span = document.getElementById("close");
    popup.classList.add("popQshow");
    span.onclick = function () {
      popup.classList.remove("popQshow");
    };

    window.onclick = function (event) {
      if (event.target == popup) {
        popup.classList.remove("show");
      }
    };
  };
  groupByDates = (data) => {
    const groups = data.reduce((groups, game) => {
      const date = game.time.split(" ")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        details: groups[date],
      };
    });
    return groupArrays;
  };
 
  sendMessage = (msg) => {
    if (msg && msg.trim() != "") {
      this.rtm
        .sendChannelMessage(msg, this.channel)
        .then(() => {
          this.setState({ disable: true });
          this.postChatAPI(msg);
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
          var sendMsg = {
            id:
              this.user == "doctor"
                ? this.props.empKey
                : localStorage.patient_n_key,
            message: msg,
            time: new Date().getTime(),
            isSeen: "false",
          };
         
          console.log("sendMessage", msg);
        })
        .catch((err) => {
          toast("Send message failed, please open console see more details.");
          //Toast.error('Send message to channel ' + params.channelName + ' failed, please open console see more details.')
          console.error(err);
        });
    }
  };

  subscribeMessageEvents = () => {
    this.rtm.on("ConnectionStateChanged", (newState, reason) => {
      console.log("reason", reason);
      if (newState === "ABORTED") {
        if (reason === "REMOTE_LOGIN") {
          //debugger;
          this.removeActiveStream(this.uid);
          //Toast.error('You have already been kicked off!')
        }
      }
    });

    // rtm.on('MessageFromPeer', async (message, peerId) => {
    //   if (message.messageType === 'IMAGE') {
    //     const blob = await rtm.client.downloadMedia(message.mediaId)
    //     blobToImage(blob, (image) => {
    //       const view = $('<div/>', {
    //         text: [' peer: ', peerId].join('')
    //       })
    //       $('#log').append(view)
    //       $('#log').append(`<img src= '${image.src}'/>`)
    //     })
    //   } else {
    //     console.log('message ' + message.text + ' peerId' + peerId)
    //     const view = $('<div/>', {
    //       text: ['message.text: ' + message.text, ', peer: ', peerId].join('')
    //     })
    //     $('#log').append(view)
    //   }

    // })

    this.rtm.on("MemberJoined", ({ channelName, args }) => {
      const memberId = args[0];
      console.log("channel ", channelName, " member: ", memberId, " joined");
    });

    this.rtm.on("MemberLeft", ({ channelName, args }) => {
      const memberId = args[0];
      console.log("channel ", channelName, " member: ", memberId, " joined");
    });

    this.rtm.on("ChannelMessage", async ({ channelName, args }) => {
      setTimeout(() => {
        this.getChatHistory();
      }, 500);
      const [message, memberId] = args;
      if (message.messageType === "IMAGE") {
        const blob = await this.rtm.client.downloadMedia(message.mediaId);
        //blobToImage(blob, (image) => {
        // const view = $('<div/>', {
        //   text: ['event: ChannelMessage ', 'channel: ', channelName, ' memberId: ', memberId].join('')
        // })
        // $('#log').append(view)
        // $('#log').append(`<img src= '${image.src}'/>`)
        // })
      } else {
        console.log(
          "channel ",
          channelName,
          ", messsage: ",
          message.text,
          ", memberId: ",
          memberId
        );
        if (this.channel !== localStorage.channelid) {
          console.log("gggggg", localStorage.getItem("channelid"));

          this.start();
        }
        setTimeout(() => {
          this.getChatHistory();
        },2000);


      }
    });
  };


  addRes = () => {
    this.setState({
      addResponse: true,
    });
  };
  getRandomColor = (val) => {
    // var letters = '0123456789ABCDEF'.split('');
    // var color = '#';
    // for (var i = 0; i < 6; i++ ) {
    //     color += letters[Math.round(Math.random() * 15)];
    let color;
    if (val === "A") {
      color = "#FF6633";
    } else if (val === "B") {
      color = "#FF33FF";
    } else if (val === "C") {
      color = "#00B3E6";
    } else if (val === "D") {
      color = "#E6B333";
    } else if (val === "E") {
      color = "#80B300";
    } else if (val === "F") {
      color = "#66994D";
    } else if (val === "G") {
      color = "#B3B31A";
    } else if (val === "H") {
      color = "#E666B3";
    } else if (val === "I") {
      color = "#4D8066";
    } else if (val === "J") {
      color = "#FF3380";
    } else if (val === "K") {
      color = "#E64D66";
    } else if (val === "L") {
      color = "#3366E6";
    } else if (val === "M") {
      color = "#809900";
    } else if (val === "N") {
      color = "#B366CC";
    } else if (val === "O") {
      color = "#991AFF";
    } else if (val === "P") {
      color = "#33991A";
    } else if (val === "Q") {
      color = "#CCCC00";
    } else if (val === "R") {
      color = "#FF4D4D";
    } else if (val === "S") {
      color = "#E666FF";
    } else if (val === "T") {
      color = "#4D8000";
    } else if (val === "U") {
      color = "#FF1A66";
    } else if (val === "V") {
      color = "#6680B3";
    } else if (val === "W") {
      color = "#9900B3";
    } else if (val === "X") {
      color = "#4D80CC";
    } else if (val === "Y") {
      color = "#CC80CC";
    } else {
      color = "#66664D";
    }

    // }
    return color;
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.sendMessage(this.state.chattext);
    }
  };
  handleChange = (e) => {
    let id = e.target.name;
    this.setState({
      [id]: e.target.value,
    });
    console.log(id, e.target.value);
    let count = e.target.value.trim().length;
    if (count >= 1) {
      this.setState({ disable: false });
    } else {
      this.setState({ disable: true });
    }
  };
  scrollToBottom = () => {
    this.messagesEndRef?.current?.scrollIntoView({ behavior: "auto" });
  };
  addMore=()=>{
    let d_ = localStorage.v__
    localStorage.v__ = parseInt(d_)+1
  }
  addLess=()=>{
    localStorage.v__=1
  }
  myScroll = () => {
    const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    console.log(`onScroll, window.scrollY: ${scrollY} `);
  };
  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  getDate = (id) => {
    let val = id;
    return moment(val.date_).format("DD-MM-YYYY");
  };
  getNumbers = (id) => {
    let val = id;
    return val.slots_.length;
  };

  getTherapist = (id) => {
    let val = id;
    return val.therapist_;
  };

 removeDuplicatesFromDetails(array) {
  const uniqueObjects = [];
  const seenObjects = new Set();
  array.forEach(obj => {
    const objectString = JSON.stringify(obj);
    if (!seenObjects.has(objectString)) {
      seenObjects.add(objectString);
      uniqueObjects.push(obj);
    }
  });
  return uniqueObjects;
  }
    
  render() {
    const style = {
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      justifyItems: "center",
      gridTemplateRows: "repeat(12, auto)",
      gridTemplateColumns: "repeat(24, auto)",
    };
    const style2 = {
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      justifyItems: "center",
      gridTemplateRows: "repeat(12, auto)",
      gridTemplateColumns: "repeat(24, auto)",
      width: "calc(100% - 230px)",
    };

    const { readyState, isOpen, volumeopen, streamList, docPatmsg, loading,total_items,total_page,page } = this.state;
    return (
      <div>
        <div className="Chat_body">
          {" "}
          <ScrollableFeed>
          {total_page>1?
          <div className="d-flex justify-content-center align-items-center my-2">
          <button 
            style={{
              backgroundColor: '#ebb859',
              border: 'none',
              color: '#215352',
              padding: '6px 18px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius:4,
              fontSize:'12px',
              fontWeight:'bold'
            }}
         onClick={()=>total_page===page?this.addLess():this.addMore()}>
       {total_page===page?'See less':'Load previous converstation'}
       {total_page===page?<ArrowDown color={"#215352"} height="15px" width="15px" />:<RefreshOutline  color={"#215352"} height="15px" width="15px" />}
       </button >
       <button  
       onClick={()=>this.messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })}
       style={{
              backgroundColor:'#ddefe5', 
              border: 'none',
              color: '#215352',
              padding: '6px 18px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius:4,
              fontSize:'12px',
              fontWeight:'bold',marginLeft:'4px'
            }}>
        Go to bottom 
        <ChevronDown color={"#215352"} height="15px" width="15px" />
       </button>
        </div>
         :null}
            {docPatmsg &&
              docPatmsg.map((list,index) => {
                return (
                  <div key={index}>
                    <p className="date-hr py-3">
                      <span>
                        {moment(list?.date).format("ll") ===
                        moment().format("ll") ? (
                          <span>Today</span>
                        ) : (
                          moment(list?.date).format("ll")
                        )}
                      </span>
                    </p>
                    {this.removeDuplicatesFromDetails(list?.details).map((item) => {
                      return (
                        <div ref={this.messagesEndRef}>
                          {" "}
                          {item.id.split("-")[1] === "EMP" ? (
                            <div className="msg-in-section ml-auto rightdiv">
                              <div className="d-flex flex-column">
                                <div className="msg-in-nt bg_secondary_ rounded">
                                  <div className="in-msg">
                                    {item.msg_type ==
                                    "Recommend Psychiatrist" ? (
                                      <div className="border rounded col-md-12  bg-white">
                                        <img
                                          style={{
                                            height: "160px",
                                            width: "100%",
                                          }}
                                          src="https://careme.sgp1.cdn.digitaloceanspaces.com/bassets/bassets/twitterassetscopy.png"
                                        />
                                        <div className="p-4 ">
                                          <p className="tex_p">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p className="tex_p">
                                            I have recommended you a psychiatric
                                            session, so that our professional
                                            will help you better.
                                          </p>
                                        </div>
                                        <div className="">
                                          <button
                                            className="px-4 btn btn-block py-1 border-none"
                                            style={{
                                              width: "100%",
                                              background: "#fff",
                                              color: "#215352",
                                              fontWeight: "bold",
                                              borderTop: "2px solid #215352",
                                            }}
                                          >
                                            Book Now
                                          </button>
                                        </div>
                                      </div>
                                    ) : item.msg_type ==
                                      "Recommend Therapist" ? (
                                      <div className="shadow-sm bg-white col-md-12">
                                        <img
                                          style={{
                                            height: "160px",
                                            width: "100%",
                                          }}
                                          src="https://careme.sgp1.cdn.digitaloceanspaces.com/bassets/bassets/twitterassetscopy.png"
                                        />
                                        <div className="p-4 ">
                                          <p className="tex_p">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p className="tex_p">
                                            I have recommended you a therapy
                                            session, so that our professional
                                            will help you better.
                                          </p>
                                        </div>
                                        <div className="">
                                          <button
                                            className="px-4 btn btn-block py-1 border-none"
                                            style={{
                                              width: "100%",
                                              background: "#fff",
                                              color: "#215352",
                                              fontWeight: "bold",
                                              borderTop: "2px solid #215352",
                                            }}
                                          >
                                            Book Now
                                          </button>
                                        </div>
                                      </div>
                                    ) : item.msg_type == "Payment" ? (
                                      <div className="shadow-sm bg-white col-md-12">
                                        <div className="px-1 py-2">
                                          <p className="tex_p _text_color ml-3">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p
                                            className="tex_p _text_color"
                                            style={{
                                              paddingTop: 10,
                                              paddingLeft: 10,
                                            }}
                                          >
                                            We're so excited to share your
                                            tailored care plan with you!
                                          </p>
                                          <p
                                            className="tex_p _text_color"
                                            style={{ paddingLeft: 10 }}
                                          >
                                            If you're ready to pay, click the
                                            button below!
                                          </p>
                                          <p
                                            className="tex_p _text_color"
                                            style={{ paddingLeft: 10 }}
                                          >
                                            We're always here to help you on
                                            this journey, no matter how many
                                            steps you take. Just reach out
                                            anytime-we'll be here for you!
                                          </p>
                                          <p
                                            className="tex_p _text_color"
                                            style={{
                                              paddingLeft: 10,
                                              opacity: 0.5,
                                            }}
                                          >
                                            With Love from Care Team
                                          </p>
                                        </div>
                                        <div className="">
                                          <button
                                            className="px-4 btn btn-block py-1 border-none"
                                            style={{
                                              width: "100%",
                                              background: "#fff",
                                              color: "#215352",
                                              fontWeight: "bold",
                                              borderTop: "2px solid #215352",
                                            }}
                                          >
                                            Pay Now
                                          </button>
                                        </div>
                                      </div>
                                    ) : item.msg_type == "assesment" ? (
                                      <div className="border rounded col-md-12  bg-white">
                                        <div className="p-4 ">
                                          <p className="tex_p">
                                            Hey,{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{
                                              paddingTop: 10,
                                              paddingLeft: 10,
                                            }}
                                          >
                                            It seems you haven't took the
                                            assesment, please take it so that we
                                            can give the best support for you.
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{ paddingLeft: 10 }}
                                          >
                                            We're always here to help you on
                                            this journey, no matter how many
                                            steps you take. Just reach out
                                            anytime-we'll be here for you!
                                          </p>
                                          <p
                                            className="tex_p"
                                            style={{
                                              paddingLeft: 10,
                                              opacity: 0.5,
                                            }}
                                          >
                                            With Love from Care Team
                                          </p>
                                        </div>
                                        <div className="text-center my-4">
                                          <button
                                            className="shadow px-4 py-1 border-none"
                                            style={{
                                              background: "#215352",
                                              color: "#fff",
                                              fontWeight: "bold",
                                              borderRadius: "4px",
                                              border: "none",
                                            }}
                                          >
                                            Take Now
                                          </button>
                                        </div>
                                      </div>
                                    ) : item.msg_type == "Slots" ? (
                                      <div className="border rounded col-md-12  bg-white">
                                        <div className="p-4 ">
                                          <p className="tex_p _text_color">
                                            {" "}
                                            Dear{" "}
                                            {this.capitalize(
                                              this.state?.pt_name
                                            )}
                                            !
                                          </p>
                                          <p className="tex_p _text_color">
                                            {" "}
                                            I'm happy to inform you that our
                                            provider{" "}
                                            <span
                                              style={{
                                                textDecorationLine: "underline",
                                              }}
                                            >
                                              {this.getTherapist(
                                                JSON.parse(item.message)
                                              )}
                                            </span>{" "}
                                            is available on{" "}
                                            <span
                                              style={{
                                                textDecorationLine: "underline",
                                              }}
                                            >
                                              {this.getDate(
                                                JSON.parse(item.message)
                                              )}
                                            </span>
                                            . We have{" "}
                                            <span
                                              style={{
                                                textDecorationLine: "underline",
                                              }}
                                            >
                                              {this.getNumbers(
                                                JSON.parse(item.message)
                                              )}
                                            </span>{" "}
                                            time slots available on that day,
                                            which are{" "}
                                          </p>
                                          <div className="row my-2">
                                            {JSON.parse(
                                              item.message
                                            ).slots_.map((i, indx) => {
                                              return (
                                                <p
                                                  key={indx}
                                                  style={{
                                                    background: "#ddefe5",
                                                    color: "#215352",
                                                  }}
                                                  className="border p-2 m-1 rounded col-md-2 text-center"
                                                >
                                                  {i}
                                                </p>
                                              );
                                            })}
                                          </div>
                                          <p className="tex_p _text_color">
                                            {" "}
                                            If you'd like to proceed with
                                            booking an appointment, you can
                                            either let me know and I'll be happy
                                            to assist you in making the
                                            reservation, or you can book
                                            directly through our system. Please
                                            let me know if there is anything
                                            further I can assist with. Thank
                                            you!
                                          </p>
                                        </div>
                                      </div>
                                    ) : item.msg_type === "Text" ? (
                                      <ReceiveMsg
                                        newMsg={item.message}
                                        link={
                                          item.message.split("/")[0].trim() ==
                                            "https:" ||
                                          item.message.split(".")[0].trim() ==
                                            "www"
                                            ? true
                                            : false
                                        }
                                      />
                                    ) : (
                                      <ReceiveMsg
                                        link={false}
                                        newMsg={
                                          <div className="file-msg">
                                            {item.message.slice(
                                              item.message.length - 4
                                            ) === ".m4a" ||
                                            item.message.slice(
                                              item.message.length - 4
                                            ) === ".mp3" ||
                                            item.message.slice(
                                              item.message.length - 4
                                            ) === ".mp4" ? (
                                              <div>
                                                <ReactAudioPlayer
                                                  src={item.message}
                                                  controls
                                                />
                                              </div>
                                            ) : item.message.slice(
                                                item.message.length - 4
                                              ) === ".jpg" ||
                                              item.message.slice(
                                                item.message.length - 4
                                              ) === ".png" ||
                                              item.message.slice(
                                                item.message.length - 4
                                              ) === "jpeg" ? (
                                              <div>
                                                <img src={item.message} />
                                              </div>
                                            ) : (
                                              <div>
                                                {/* {item.message} */}
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <a
                                                    href={item.message}
                                                    className="cloud-dwnl"
                                                    target="_blank"
                                                  >
                                                    {item.message.split("/")[5]}
                                                    <CloudDownloadOutline
                                                      color={"#00000"}
                                                      title="Download"
                                                      height="17px"
                                                      width="17px"
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <p
                                  style={{ fontSize: "13px" }}
                                  className="m-0 px-3 py-1 ml-auto"
                                >
                                  {moment(item.time).format("D MMM, h:mm a")}{" "}
                                  {item.isSeen ? (
                                    <BsCheckAll size={22} color="#679df5" />
                                  ) : null}
                                </p>
                              </div>
                              <div className="msg-in-img">
                                <div
                                  role="button"
                                  tabIndex="-1"
                                  className="in-avatar"
                                  style={{
                                    background: this.getRandomColor(
                                      item.name.slice(0, 1).toUpperCase()
                                    ),
                                  }}
                                >
                                  {item.name.slice(0, 1).toUpperCase()}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="msg-in-section mr-auto ">
                                <div className="msg-in-img">
                                  <div
                                    role="button"
                                    tabIndex="-1"
                                    className="in-avatar"
                                    style={{
                                      background: this.getRandomColor(
                                        item.name.slice(0, 1).toUpperCase()
                                      ),
                                    }}
                                  >
                                    {item.name.slice(0, 1).toUpperCase()}
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="msg-in-nt rounded">
                                    <div className="in-msg">
                                      {item.msg_type === "Text" ? (
                                        <SendSms
                                          newMsg={item.message}
                                          link={
                                            item.message.split("/")[0].trim() ==
                                              "https:" ||
                                            item.message.split(".")[0].trim() ==
                                              "www"
                                              ? true
                                              : false
                                          }
                                        />
                                      ) : (
                                        <SendSms
                                          link={false}
                                          newMsg={
                                            <div className="file-msg">
                                              {item.message.slice(
                                                item.message.length - 4
                                              ) === ".m4a" ||
                                              item.message.slice(
                                                item.message.length - 4
                                              ) === ".mp3" ||
                                              item.message.slice(
                                                item.message.length - 4
                                              ) === ".mp4" ? (
                                                <div>
                                                  <ReactAudioPlayer
                                                    src={item.message}
                                                    controls
                                                  />
                                                </div>
                                              ) : item.message.slice(
                                                  item.message.length - 4
                                                ) === ".jpg" ||
                                                item.message.slice(
                                                  item.message.length - 4
                                                ) === ".png" ||
                                                item.message.slice(
                                                  item.message.length - 4
                                                ) === "jpeg" ? (
                                                <img src={item.message} />
                                              ) : (
                                                <div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <a
                                                      href={item.message}
                                                      className="cloud-dwnl"
                                                      target="_blank"
                                                    >
                                                      Download File
                                                      <CloudDownloadOutline
                                                        color={"#00000"}
                                                        title="Download"
                                                        height="17px"
                                                        width="17px"
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <p
                                    style={{ fontSize: "12px" }}
                                    className="m-0 pl-3 py-1"
                                  >
                                    {moment(item.time).format("D MMM, h:mm a")}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </ScrollableFeed>
        </div>

        <div className="">
          <div className="textarea_sec">
            <div className="btn_sec">
              <textarea
                value={this.state.chattext}
                name="chattext"
                onKeyPress={this.handleKeyPress}
                onChange={this.handleChange}
                placeholder="Enter your message.."
              />
              <div className="action-buttons">
                <div className="send-section">
                  <div style={{ marginRight: "10px", alignSelf: "center" }}>
                    <div>
                      <label for="flUpload">
                        <div style={{ cursor: "pointer" }}>
                          <AttachOutline
                            color={"#215352"}
                            height="30px"
                            width="30px"
                          />
                        </div>
                        <input
                          type="file"
                          id="flUpload"
                          onChange={this.onFileChange}
                          style={{ display: "none" }}
                        />
                      </label>
                    </div>
                  </div>
                  <button
                    className="send-btn"
                    style={{ background: this.state.disable ? "grey" : null }}
                    disabled={this.state.disable}
                    onClick={() => {
                      this.sendMessage(this.state.chattext);
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>{this.fileData()}</div>
        <FileDrop
          onFrameDragEnter={(event) => console.log("onFrameDragEnter", event)}
          onFrameDragLeave={(event) => console.log("onFrameDragLeave", event)}
          onFrameDrop={(event) => console.log("onFrameDrop", event)}
          onDragOver={(event) => console.log("onDragOver", event)}
          onDragLeave={(event) => console.log("onDragLeave", event)}
          onDrop={(files, event) => this.onDragChange(files, event)}
        >
          Drop files here
        </FileDrop>
      </div>
    );
  }
}

export default AgoraCanvas;
