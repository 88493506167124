import React from "react";
import axios from "axios";
import InitialImage from "../assets/images/chat-icon-wait.png";
import {
  BookOutline,
  CashSharp,
  Desktop,
  FileTrayStacked,
  ListCircle,
  MailOpen,
  Person,
} from "react-ionicons";
import {
  PhonePortrait,
  KeyOutline,
  Clipboard,
  ArrowForward,
} from "react-ionicons";
import { useEffect } from "react";
import { BASEURL, headers, Synapheaders } from "./utils/constant";
import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { BsCircle, BsCircleFill } from "react-icons/bs";

const Profile = (props) => {
  const animatedComponents = makeAnimated();
  const [user, setUser] = useState(props.users);
  const [symptoms, setSymptoms] = useState([]);
  const [therapist, setTherapist] = useState([]);
  const [list, setList] = useState([]);
  const [status, setStatus] = useState(false);
  const [exercise, setExercise] = useState(false);
  const [recommend, setRecommend] = useState(false);
  const [sesion, setSesion] = useState(false);
  const [check, setCheck] = useState(false);
  const [problem, setProblem] = useState(false);
  const [order, setOrder] = useState(false);
  const [slots, setSlots] = useState(false);
  const [remind, setRemind] = useState(false);
  const [selectedAssesmnet, setSelectedAssesment] = useState();
  const [assesment, setAssesment] = useState(false);
  const [assesmentcount, setAssesmentCount] = useState();
  const [goal, setGoal] = useState(false);
  const [orderdetails, setOrderDetails] = useState({
    patient_n_key: localStorage.patient_n_key,
    doctor_n_key: "",
    total_sessions_recommended: "",
    hospital_n_key: "CAR-HOS-1",
    org_n_key: "ORGID-1",
    created_by_id: localStorage.employee_n_key,
    created_by_name: "Careme",
  });
  const [slotDate, setSlotDate] = useState(new Date());
  const [slotTherapist, setSlotTherapist] = useState();
  const [slotsAvailable, setSlotsAvalilable] = useState([]);
  const [nextSlot, setNextSlot] = useState();
  const [problemdetails, setProblemDetails] = useState({
    created_by_id: localStorage.employee_n_key,
    goal_order_id: "",
    hospital_n_key: "CAR-HOS-1",
    org_n_key: "ORGID-1",
    patient_n_key: localStorage.patient_n_key,
    goal_title: "",
  });
  const [assesments, setAssesments] = useState({
    patient_n_key: localStorage.patient_n_key,
    form_name: "",
    order_form_id: "",
    frequency: "",
    end_date: new Date(),
    hospital_n_key: "CAR-HOS-1",
    org_n_key: "ORGID-1",
    created_by_id: localStorage.employee_n_key,
    created_by_name: "Careme",
  });
  const [freq, setFreq] = useState([
    { label: "One time", value: "One time" },
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Every 2 days", value: "Every 2 days" },
    { label: "Every 3 days", value: "Every 3 days" },
    { label: "Every 4 days", value: "Every 4 days" },
    { label: "Every 2 weeks", value: "Every 2 weeks" },
    { label: "Every 3 weeks", value: "Every 3 weeks" },
    { label: "Every 4 weeks", value: "Every 4 weeks" },
    { label: "Every 6 weeks", value: "Every 6 weeks" },
    { label: "Every 2 months", value: "Every 2 months" },
    { label: "Every 4 months", value: "Every 4 months" },
    { label: "Every 6 months", value: "Every 6 months" },
    { label: "Yearly", value: "Yearly" },
  ]);
  const [exerciseDetails, setExerciseDetails] = useState({
    created_by_id: localStorage.employee_n_key,
    created_by_name: "Careme",
    exe_doc_id: "",
    hospital_n_key: "CAR-HOS-1",
    org_n_key: "ORGID-1",
    patient_n_key: localStorage.patient_n_key,
  });
  const [exclist, setExclist] = useState([]);
  const [pendingOrder, setPendingorder] = useState();
  const [pendingAssesment, setPendingAssesment] = useState();
  const [count, setCount] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [Timebound, setTimebound] = useState([
    { name: "A Week", selct: false },
    { name: "A Month", selct: false },
    { name: "3 Months", selct: false },
    { name: "6 Months", selct: false },
    { name: "12 Months", selct: false },
    { name: "Not sure", selct: false },
  ]);
  const [realistic, setRealistic] = useState([
    "Strongly Disagree",
    "Slightly",
    "Neutral",
    "Slightly Agree",
    "Agree",
    "Strongly Agree",
  ]);
  const [createGoal, setCreateGoal] = useState({
    achieve: "",
    goal: "",
    help_to_reach: "",
    life_after_goal: "",
    prioritize: "",
    status: "",
    time_bound: "",
    time_bound_range: "",
    what_get_inthis: "",
    why_this: "",
    created_by_id: localStorage.employee_n_key,
    created_by_name: "Careme",
    hospital_n_key: "CAR-HOS-1",
    org_n_key: "ORGID-1",
    patient_n_key: localStorage.patient_n_key,
  });
  const [emptygoal, setEmptygoal] = useState({
    achieve: "",
    goal: "",
    help_to_reach: "",
    life_after_goal: "",
    prioritize: "",
    status: "",
    time_bound: "",
    time_bound_range: "",
    what_get_inthis: "",
    why_this: "",
    created_by_id: localStorage.employee_n_key,
    created_by_name: "Careme",
    hospital_n_key: "CAR-HOS-1",
    org_n_key: "ORGID-1",
    patient_n_key: localStorage.patient_n_key,
  });

  const validateVal = (values) => {
    const createGoal = values;
    if (
      createGoal.goal.length > 2 &&
      createGoal.why_this.length > 2 &&
      createGoal.what_get_inthis.length > 2 &&
      ((createGoal.time_bound && createGoal.time_bound == "Long Term Goal") ||
        (createGoal.time_bound == "Short Term Goal" &&
          createGoal.time_bound_range)) &&
      createGoal.life_after_goal.length > 2 &&
      createGoal.achieve
    ) {
      setDisable(false);
    } else setDisable(true);
  };
  useEffect(() => {
    validateVal(createGoal);
  }, [createGoal]);

  const handleGoal = (id, val) => {
    setCreateGoal({ ...createGoal, [id]: val });
  };

  const toDashboard = () => {
    window.location.href = `https://getehr.curegrid.co/linktopdashboard/${localStorage.patient_n_key}`;
    // window.location.href=`https://getehr.synapstics.com/linktopdashboard/${localStorage.patient_n_key}`
  };
  const gotoPay = () => {
    window.location.href = `https://curegrid.co/linktopayment/${localStorage.patient_n_key}`;
    // window.location.href=`http://localhost:8080/linktopayment/${localStorage.patient_n_key}`
  };
  const getSes = (id) => {
    axios
      .post(
        BASEURL + "api/chat/list_chat/",
        {
          channelid: localStorage.channelid,
          id: localStorage.patient_n_key,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
        }
      )
      .then((response) => {
        if (response.data.status == "success") {
          if (!props?.users?.first_name) {
            // setUser({
            //   ...user,
            //   unique_health_n_key: response.data.patent[0].unique_health_n_key,
            //   first_name: response.data.patent[0].first_name,
            //   last_name: response.data.patent[0].last_name,
            //   email: response.data.patent[0].email,
            //   phone_number: response.data.patent[0].phone_number,
            //   coach: response.data.patent[0].coach,
            // });
          }
        }
      });
  };

  useEffect(() => {
    if (props?.users?.first_name) {
      console.log("Pat archived", props);
      setUser(props?.users);
    } else {
      let data = props?.users;
      console.log("===============>", data);
      if (data) {
        getSes();
      }
    }
  }, [props]);

  const getProblem = async () => {
    await axios.get(BASEURL + "api/goal_title/", { headers }).then((res) => {
      if (res.data.status == "success") {
        let arr = [];
        res.data.data.forEach((i) => {
          return arr.push({ label: i.title, value: i.goal_order_id });
        });
        setSymptoms(arr);
      }
    });
  };
  const getExercise = async () => {
    const data = { exe_name: "" };
    await axios
      .post(BASEURL + "api/search_exercise/", data, {
        headers: {
          Authorization: "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          let arr = [];
          res.data.data.forEach((i) => {
            return arr.push({ label: i.exe_name, value: i.exe_doc_id });
          });
          setExclist(arr);
        }
      });
  };
  const getAssesment = async () => {
    await axios
      .post(
        BASEURL + "api/list_orderform/",
        { org_n_key: "ORGID-1" },
        { headers }
      )
      .then((res) => {
        if (res.data.status == "success") {
          let arr = [];
          res.data.data.forEach((i) => {
            return arr.push({ label: i.title, value: i.order_form_id });
          });
          setList(arr);
        }
      });
  };

  const getTherapist = async () => {
    const data = {
      employee_n_key: localStorage.employee_n_key,
      hospital_n_key: "CAR-HOS-1",
      org_n_key: "ORGID-1",
    };
    await axios
      .post(BASEURL + "api/doctor_calendar_search/", data, { headers })
      .then((res) => {
        if (res) {
          let arr = [];
          res.data.forEach((i) => {
            return arr.push({ label: i.first_name, value: i.employee_n_key });
          });
          setTherapist(arr);
        }
      });
  };

  const getPendings = async () => {
    const data = {
      patient_n_key: localStorage.patient_n_key,
    };
    await axios
      .post(BASEURL + "api/caremeapp/followup_recommended/", data, { headers })
      .then((res) => {
        if (res.data.status == "success") {
          setPendingorder(res.data.data);
          let dat =res.data.formcheck
          let arr = [];
          dat.forEach((i) => {
            return arr.push({ label: i.form_name, value: i.order_form_id });
          });
          setPendingAssesment(arr);
          // setPendingAssesment(res.data.formcheck)
          setAssesmentCount(res.data.unfilled_count);
        }
      });
  };
  useEffect(() => {
    getProblem();
    getTherapist();
    getAssesment();
    getExercise();
    getPendings();
  }, []);
  useEffect(() => {
    getProblem();
    getTherapist();
    getAssesment();
    getExercise();
    getPendings();
  }, [props]);
  const addOrders = async () => {
    const data = { ...orderdetails };
    data.created_by_id = localStorage.employee_n_key;
    data.patient_n_key = localStorage.patient_n_key;
    await axios
      .post(BASEURL + "api/add_followup_orders/", data, {
        headers: {
          Authorization: "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setOrder(false);
        }
      });
  };
  const handleform = (item) => {
    setAssesments({
      ...assesments,
      form_name: item.label,
      order_form_id: item.value,
    });
  };
  const handleasses = (item) => {
    setSelectedAssesment(item.value);
  };
  const addProblem = (item) => {
    let arr = [];
    item.forEach((i) => {
      return arr.push(i.value);
    });
    setProblemDetails({ ...problemdetails, goal_order_id: arr });
  };
  const addtherapist = (item) => {
    setOrderDetails({ ...orderdetails, doctor_n_key: item.value });
  };
  const handleTherapist = async (item) => {
    setSlotTherapist(item.value);
    const data = {
      available_date: moment(slotDate).format("YYYY-MM-DD"),
      doc_app_id: item.value,
      languages_known: "",
      org_n_key: "ORGID-1",
      return_type: null,
    };
    await axios
      .post(BASEURL + "api/therapist_available_report/", data, { headers })
      .then((res) => {
        if (res.data.status == "success") {
          if (res.data?.data[0]?.work?.length > 0) {
            setSlotsAvalilable(res.data.data[0].work);
            setNextSlot("");
          } else {
            setSlotsAvalilable([]);
            setNextSlot(res.data?.data[0]?.next_date);
          }
        }
      });
  };
  const handleSlot = async (val) => {
    setSlotDate(val);
    const data = {
      available_date: moment(val).format("YYYY-MM-DD"),
      doc_app_id: slotTherapist,
      languages_known: "",
      org_n_key: "ORGID-1",
      return_type: null,
    };
    await axios
      .post(BASEURL + "api/therapist_available_report/", data, { headers })
      .then((res) => {
        if (res.data.status == "success") {
          if (res.data?.data[0]?.work?.length > 0) {
            setSlotsAvalilable(res.data?.data[0].work);
            setNextSlot("");
          } else {
            setSlotsAvalilable([]);
            setNextSlot(res.data?.data[0]?.next_date);
          }
        }
      });
  };
  const sendSlots = () => {
    let emp = therapist.filter((i) => i.value == slotTherapist)[0].label;
    let dt = JSON.stringify({
      slots_: slotsAvailable,
      date_: slotDate,
      therapist_: emp,
    });
    const data = {
      id: localStorage.employee_n_key,
      channelid: localStorage.channelid,
      message: dt,
      isSeen: "False",
      patient_n_key: localStorage.patient_n_key,
      hospital_n_key: "CAR-HOS-1",
      org_n_key: "ORGID-1",
      msg_type: "Slots",
    };

    axios
      .post(BASEURL + "api/chat/send_message/", data, { headers })
      .then((response) => {
        setSlotDate(new Date());
        setSlotsAvalilable([]);
        setSlotTherapist("");
        setSlots(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addSymptoms = async () => {
    const data = { ...problemdetails };
    data.created_by_id = localStorage.employee_n_key;
    data.patient_n_key = localStorage.patient_n_key;
    await axios
      .post(BASEURL + "api/order_goals_exercise/", data, { headers })
      .then((res) => {
        if (res.data.status == "success") {
          setProblem(false);
        }
      });
  };
  const addExercise = async () => {
    const data = { ...exerciseDetails };
    data.created_by_id = localStorage.employee_n_key;
    data.patient_n_key = localStorage.patient_n_key;
    await axios
      .post(BASEURL + "api/user_exercise/", data, {
        headers: {
          Authorization: "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setExercise(false);
        }
      });
  };
  const addGoal = async () => {
    const data = { ...createGoal };
    data.created_by_id = localStorage.employee_n_key;
    data.patient_n_key = localStorage.patient_n_key;
    await axios
      .post(BASEURL + "api/add_goals/", data, {
        headers: {
          Authorization: "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setGoal(false);
          setCreateGoal(emptygoal);
        }
      });
  };
  const addAssesments = async () => {
    const data = { ...assesments };
    data.created_by_id = localStorage.employee_n_key;
    data.patient_n_key = localStorage.patient_n_key;
    data.end_date = moment(data.end_date).format("YYYY-MM-DD");
    await axios
      .post(BASEURL + "api/add_therapyforms/", data, {
        headers: {
          Authorization: "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setAssesment(false);
        }
      });
  };

  const postChatAPI = () => {
    const data = {
      id: localStorage.employee_n_key,
      channelid: localStorage.channelid,
      message: "",
      isSeen: "False",
      patient_n_key: localStorage.patient_n_key,
      hospital_n_key: "CAR-HOS-1",
      org_n_key: "ORGID-1",
      msg_type: sesion,
    };

    axios
      .post(BASEURL + "api/chat/send_message/", data, { headers })
      .then((response) => {
        console.log(response);
        setRecommend(false);
        setCheck(false);
        setSesion("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postAssesmentAPI = () => {
    const data = {
      id: localStorage.employee_n_key,
      channelid: localStorage.channelid,
      message: selectedAssesmnet,
      isSeen: "False",
      patient_n_key: localStorage.patient_n_key,
      hospital_n_key: "CAR-HOS-1",
      org_n_key: "ORGID-1",
      msg_type: "assesment",
    };

    axios
      .post(BASEURL + "api/chat/send_message/", data, { headers })
      .then((response) => {
        console.log(response);
        setRemind(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      <Modal size="lg" isOpen={order}>
        <ModalHeader>Add Order</ModalHeader>
        <ModalBody>
          <div className="order-inpt">
            <label className="col-md-6 order-label">
              Enter the number of sessions recommended
            </label>
            <input
              defaultValue={orderdetails.total_sessions_recommended}
              type="number"
              onChange={(e) => {
                setOrderDetails({
                  ...orderdetails,
                  total_sessions_recommended: e.target.value,
                });
              }}
              className="form-control"
            />
          </div>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">Select Therapist</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={problemdetails.title}
              onChange={addtherapist}
              options={therapist}
              className="col-md-6"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setOrder(false);
              setOrderDetails({});
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !orderdetails.total_sessions_recommended ||
              !orderdetails.doctor_n_key
            }
            color="primary"
            onClick={() => {
              addOrders();
            }}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={slots}>
        <ModalHeader>Send Therapist Slots</ModalHeader>
        <ModalBody>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">Select Therapist</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={slotTherapist}
              onChange={handleTherapist}
              options={therapist}
              className="col-md-6"
            />
          </div>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">Select Date</label>
            <DatePicker
              className="col-md-6 form-control"
              selected={new Date(slotDate)}
              onChange={(date) => handleSlot(date)}
            />
          </div>
          {slotsAvailable.length > 0 && (
            <div style={{ marginTop: "15px" }} className="order-inpt">
              <label
                style={{ marginLeft: "-15px" }}
                className="order-label col-md-6"
              >
                Avaliable slots
              </label>
              <div className="col-md-6 row">
                {slotsAvailable.map((i, indx) => {
                  return (
                    <p
                      style={{ background: "#ddefe5", color: "#215352" }}
                      className="border p-2 m-1 rounded col-md-2"
                    >
                      {i}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
          {nextSlot && (
            <div style={{ marginTop: "15px" }} className="order-inpt">
              <label
                style={{ marginLeft: "-15px" }}
                className="order-label col-md-6"
              >
                Next Available Date
              </label>
              <div className="col-md-6 row">
                <p
                  style={{
                    fontFamily: "Source Serif Pro",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                >
                  {nextSlot}
                </p>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setSlots(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={slotsAvailable.length == 0}
            color="primary"
            onClick={() => {
              sendSlots();
            }}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={problem}>
        <ModalHeader>Add Problem</ModalHeader>
        <ModalBody>
          <div className="order-inpt">
            <label className="col-md-6 order-label">
              Select the Symptoms/Problem
            </label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={problemdetails.title}
              isMulti
              onChange={addProblem}
              className="col-md-4"
              options={symptoms}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setProblem(false);
              setProblemDetails({
                created_by_id: localStorage.employee_n_key,
                goal_order_id: "",
                hospital_n_key: "CAR-HOS-1",
                org_n_key: "ORGID-1",
                patient_n_key: localStorage.patient_n_key,
                goal_title: "",
              });
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={problemdetails.goal_order_id.length == 0}
            color="primary"
            onClick={() => {
              addSymptoms();
            }}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={assesment}>
        <ModalHeader>Add Assessments</ModalHeader>
        <ModalBody>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">Select Assessment</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={assesments.form_name}
              onChange={handleform}
              options={list}
              className="col-md-6"
            />
          </div>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">Select Frequency</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={problemdetails.title}
              onChange={(item) =>
                setAssesments({ ...assesments, frequency: item.value })
              }
              options={freq}
              className="col-md-6"
            />
          </div>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">End Date</label>
            <DatePicker
              className="col-md-6 form-control"
              selected={new Date(assesments.end_date)}
              onChange={(date) =>
                setAssesments({ ...assesments, end_date: date })
              }
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setAssesment(false);
              setAssesments({
                patient_n_key: localStorage.patient_n_key,
                form_name: "",
                order_form_id: "",
                frequency: "",
                end_date: new Date(),
                hospital_n_key: "CAR-HOS-1",
                org_n_key: "ORGID-1",
                created_by_id: localStorage.employee_n_key,
                created_by_name: "Careme",
              });
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !assesments.frequency ||
              !assesments.end_date ||
              !assesments.form_name
            }
            color="primary"
            onClick={() => {
              addAssesments();
            }}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={remind}>
        <ModalHeader>Remind Assessments</ModalHeader>
        <ModalBody>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">Select Assessment</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              // defaultValue={assesments.form_name}
              onChange={handleasses}
              options={pendingAssesment}
              className="col-md-6"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setRemind(false);
              setSelectedAssesment({});
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedAssesmnet}
            color="primary"
            onClick={() => {
              postAssesmentAPI();
            }}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={exercise}>
        <ModalHeader>Add Exercise</ModalHeader>
        <ModalBody>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <label className="order-label col-md-6 ">Select Exercise</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={exerciseDetails.exe_doc_id}
              onChange={(item) =>
                setExerciseDetails({
                  ...exerciseDetails,
                  exe_doc_id: item.value,
                })
              }
              options={exclist}
              className="col-md-6"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setExercise(false);
              setExerciseDetails({});
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!exerciseDetails.exe_doc_id}
            color="primary"
            onClick={() => {
              addExercise();
            }}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={recommend}>
        <ModalHeader>Recommend Appointment</ModalHeader>
        <ModalBody>
          <div style={{ marginTop: "15px" }} className="order-inpt">
            <div
              onClick={() => setSesion("Recommend Therapist")}
              style={{ cursor: "pointer" }}
              className="m-2"
            >
              <p
                style={{
                  background:
                    sesion == "Recommend Therapist" ? "#10a35a" : "#fff",
                  color: sesion == "Recommend Therapist" ? "#fff" : "#000",
                  fontWeight: "bold",
                }}
                className="border rounded px-3 py-2 shadow shadow "
              >
                Recommend Therapist
              </p>
            </div>
            <div
              onClick={() => setSesion("Recommend Psychiatrist")}
              style={{ cursor: "pointer" }}
              className="m-2"
            >
              <p
                style={{
                  background:
                    sesion == "Recommend Psychiatrist" ? "#10a35a" : "#fff",
                  color: sesion == "Recommend Psychiatrist" ? "#fff" : "#000",
                  fontWeight: "bold",
                }}
                className="border rounded px-3 py-2 shadow "
              >
                Recommend Psychiatrist
              </p>
            </div>
          </div>
        </ModalBody>
        {sesion && (
          <div className="d-flex flex-row text-center mx-auto my-3">
            <input
              className="m-1"
              type="checkbox"
              defaultValue={check}
              onChange={() => setCheck(!check)}
            />
            <label style={{ fontWeight: "bold" }} className="m-1">
              Confirm and {sesion}
            </label>
          </div>
        )}
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setRecommend(false);
              setSesion("");
              setCheck(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!check}
            color="primary"
            onClick={() => {
              postChatAPI();
            }}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal scrollable size="lg" isOpen={goal}>
        <ModalHeader>Add Goals</ModalHeader>
        <ModalBody>
          <div className="questions_">
            <label>1. You want to work upon?</label>
            <input
              type="text"
              defaultValue={createGoal.goal}
              placeholder="Type your answer here (minimum 3 characters)"
              onChange={(e) => handleGoal("goal", e.target.value)}
              className="form-control"
            />
          </div>
          <div className="questions_">
            <label>2.Reason for choosing this goal?</label>
            <input
              type="text"
              defaultValue={createGoal.why_this}
              placeholder="Type your answer here (minimum 3 characters)"
              onChange={(e) => handleGoal("why_this", e.target.value)}
              className="form-control"
            />
          </div>
          <div className="questions_">
            <label>3.What might get in the way of this goal?</label>
            <input
              type="text"
              defaultValue={createGoal.what_get_inthis}
              placeholder="Type your answer here (minimum 3 characters)"
              onChange={(e) => handleGoal("what_get_inthis", e.target.value)}
              className="form-control"
            />
          </div>
          <div className="questions_">
            <label>4.Prioritize the goal</label>
            <div className="goal_radio">
              <button
                className="questions_button"
                style={{
                  backgroundColor:
                    createGoal.prioritize == "High" ? "#145adc" : null,
                }}
                onClick={() => {
                  handleGoal("prioritize", "High");
                }}
              >
                {createGoal.prioritize == "High" ? (
                  <BsCircleFill />
                ) : (
                  <BsCircle />
                )}{" "}
                High
              </button>
              <button
                className="questions_button"
                style={{
                  backgroundColor:
                    createGoal.prioritize == "Medium" ? "#145adc" : null,
                }}
                onClick={() => {
                  handleGoal("prioritize", "Medium");
                }}
              >
                {createGoal.prioritize == "Medium" ? (
                  <BsCircleFill />
                ) : (
                  <BsCircle />
                )}{" "}
                Medium
              </button>
            </div>
          </div>
          <div className="questions_">
            <label>5.What(or Who) will help you reach this goal?</label>
            <input
              type="text"
              defaultValue={createGoal.help_to_reach}
              placeholder="Type your answer here (minimum 3 characters)"
              onChange={(e) => handleGoal("help_to_reach", e.target.value)}
              className="form-control"
            />
          </div>
          <div className="questions_">
            <label>6.When will this goal be achieved by?</label>
            <div className="goal_radio">
              <button
                className="questions_button"
                style={{
                  backgroundColor:
                    createGoal.time_bound == "Short Term Goal"
                      ? "#145adc"
                      : null,
                }}
                onClick={() => handleGoal("time_bound", "Short Term Goal")}
              >
                {createGoal.time_bound == "Short Term Goal" ? (
                  <BsCircleFill />
                ) : (
                  <BsCircle />
                )}{" "}
                Short Term Goal
              </button>
              <button
                className="questions_button"
                style={{
                  backgroundColor:
                    createGoal.time_bound == "Long Term Goal"
                      ? "#145adc"
                      : null,
                }}
                onClick={() => handleGoal("time_bound", "Long Term Goal")}
              >
                {createGoal.time_bound == "Long Term Goal" ? (
                  <BsCircleFill />
                ) : (
                  <BsCircle />
                )}{" "}
                Long Term Goal
              </button>
            </div>
            {createGoal.time_bound == "Short Term Goal" ? (
              <>
                <div className="goal_radio_">
                  {Timebound.map((i, indx) => {
                    return (
                      <button
                        className="questions_button"
                        style={{
                          backgroundColor:
                            createGoal.time_bound_range == i.name
                              ? "#145adc"
                              : null,
                        }}
                        key={indx}
                        onClick={() => handleGoal("time_bound_range", i.name)}
                      >
                        {createGoal.time_bound_range == i.name ? (
                          <BsCircleFill />
                        ) : (
                          <BsCircle />
                        )}{" "}
                        {i.name}
                      </button>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
          <div className="questions_">
            <label>
              7. What might your life look like if you were able to reach this
              goal?
            </label>
            <input
              type="text"
              defaultValue={createGoal.life_after_goal}
              placeholder="Type your answer here (minimum 3 characters)"
              onChange={(e) => handleGoal("life_after_goal", e.target.value)}
              className="form-control"
            />
          </div>

          <div className="questions_">
            <label>8. How motivated are you to achieve this goal?</label>
            <div className="scale">
              {count.map((i, ind) => {
                return (
                  <button
                    onClick={() => handleGoal("achieve", i)}
                    className="range"
                    style={{
                      padding: "10px",
                      height: "40px",
                      backgroundColor:
                        createGoal.achieve === i
                          ? "#fff"
                          : ind == 0
                          ? "#DFE257"
                          : ind == 1
                          ? "#ECEA0E"
                          : ind == 2
                          ? "#FAF701"
                          : ind == 3
                          ? "#88E16A"
                          : ind == 4
                          ? "#5FC713"
                          : ind == 5
                          ? "#32A404"
                          : ind == 6
                          ? "#147325"
                          : ind == 7
                          ? "#E98838"
                          : ind == 8
                          ? "#D13434"
                          : ind == 9
                          ? "#FF0200"
                          : "#000",
                    }}
                  >
                    <p
                      style={{
                        color: createGoal.achieve === i ? "#000" : "#fff",
                        alignSelf: "center",
                        marginTop: "-3px",
                        fontWeight: "bold",
                      }}
                    >
                      {i}
                    </p>
                  </button>
                );
              })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setGoal(false);
            }}
          >
            Cancel
          </Button>
          <Button disabled={disable} color="primary" onClick={() => addGoal()}>
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>

      {user ? (
        <div
        //d-none d-md-block
          className="col-md-3 "
          style={{
            overflowY: "scroll",
            maxHeight: "100%",
            borderLeft: "1px solid #215352",
          }}
        >
          <div className="bg_secondary d-flex pt-md-4 pb-md-3 px-md-4 border shadow-sm">
            <div className="chat-i-image">
              <span>{user?.first_name?.slice(0, 1).toUpperCase()}</span>
            </div>
            <div className="chat-user-name">
              <span className="name-rightside header_font">
                {user.last_name === null
                  ? user.first_name
                  : user.first_name + " " + user.last_name}
              </span>
              {user?.status == "Online" ? (
                <p className="mt-1">Online</p>
              ) : user?.status == "Background" ? (
                <p className="mt-1">Online - Not Active</p>
              ) : user?.status == "Offline" && user?.last_seen ? (
                <p className="mt-1">
                  Last seen: {moment(user?.last_seen).fromNow()}
                </p>
              ) : (
                "Offline"
              )}
            </div>
          </div>
          <div className="px-md-4 pt-md-3">
            <p className="profile-contact-heading m-0">Coach Details</p>
            <hr className="m-0 my-2 bg_color" style={{padding:'1px'}} />
          </div>
          <div className="px-md-4 mx-md-2 pt-2">
            <div className="d-flex justify-content-around ">
              <span className="tex_p h6 _text_color mr-auto">
                <Person color={"#215352"} height="23px" width="23px" /> Coach:
              </span>
              <span className="tex_p h6 px-md-3 _text_color mr-auto">{user?.coach||'Not assigned yet'}</span>
            </div>
          </div>
          <div className="px-md-4 pt-md-3">
            <p className="profile-contact-heading m-0">Personal Info</p>
            <hr className="m-0 my-2 bg_color" style={{padding:'1px'}} />
            <div className="mx-md-3 pt-2">
              <div className="d-flex justify-content-center _text_color col-md-12">
                <span className="tex_p h6 mr-auto">
                  <MailOpen  color={"#215352"} height="23px" width="23px" /> Email:
                </span>
                <span className="tex_p text-center h6 px-md-2">{user?.email||'-'}</span>
              </div>
              <div className="d-flex pt-2 justify-content-around _text_color">
                <span className="tex_p h6 mr-auto">
                  <PhonePortrait color={"#215352"} height="23px" width="23px" /> Phone:
                </span>
                <span className="tex_p h6 px-md-3">{user?.phone_number||'-'}</span>
              </div>
              <div className="d-flex pt-2 justify-content-around _text_color">
                <span className="tex_p h6 mr-auto">
                  <KeyOutline color={"#215352"} height="23px" width="23px" />{" "}
                  UHID:
                </span>
                <span className="tex_p h6 px-md-3">
                  {user?.unique_health_n_key||'-'}
                </span>
              </div>
              <div className="d-flex pt-2 justify-content-around _text_color">
                <span className="tex_p h6 mr-auto">
                  <Desktop color={"#215352"} height="22px" width="22px" />{" "}
                  Desk:
                </span>
                <span className="tex_p h6 px-md-3">
                <a target="_blank" href={`${BASEURL}linktopdashboard/${localStorage.patient_n_key}`}
                  >Patient Desk <ArrowForward 
                  color={"#0566ff"}
                  height="23px"
                  width="23px" /></a>
                </span>
              </div>
            </div>
          </div>
          {/* <div className="px-md-5">
            <div
              style={{ cursor: "pointer" }}
              className="dashboard-link px-md-3"
            >
              <button
                style={{ cursor: "pointer" }}
                type="button"
                onClick={() => toDashboard()}
              >
                <div style={{ cursor: "pointer" }} className="ds-al">
                  <Clipboard color={"#215352"} height="25px" width="25px" />
                  <a
                    href={`${BASEURL}/linktopdashboard/${localStorage.patient_n_key}`}
                  >
                    <span>Patient Dashboard</span>
                    <ArrowForward
                      color={"#0566ff"}
                      height="25px"
                      width="25px"
                    />
                  </a>
                </div>
              </button>
            </div>
          </div> */}
          <div className="px-md-4 pt-md-4">
            <p className="profile-contact-heading m-0">Appointment Details</p>
            <hr className="m-0 my-2 bg_color" style={{padding:'1px'}} />
          </div>
          <div className="mx-auto p-0 mt-3 pb-md-4">
            <div className="d-flex _text_color justify-content-around px-md-3 mx-md-4">
              <span className="tex_p h6 mr-auto">
                <CashSharp color={"#215352"} height="23px" width="23px" />{" "}
                Remaining Credits :
              </span>
              <span className="tex_p h6 px-md-3">{user?.session||0}</span>
            </div>
            <div className="d-flex _text_color px-md-3 mx-md-4 justify-content-around pt-1">
              <span className="tex_p h6 mr-auto">
                <BookOutline color={"#215352"} height="23px" width="23px" />{" "}
                Pending Assessments:
              </span>
              <span className="tex_p h6 px-md-3">
                {assesmentcount && assesmentcount || 0}
              </span>
            </div>
            <div className="d-flex _text_color px-md-3 mx-md-4 justify-content-around pt-1">
              <span className="tex_p h6 mr-auto">
                <ListCircle color={"#215352"} height="23px" width="23px" />{" "}
                Pending Orders Count:
              </span>
              <span className="tex_p h6 px-md-3">
                {pendingOrder && pendingOrder[0]?.pending||0}
              </span>
            </div>
            <div className="px-md-4 pt-md-4">
              <p className="profile-contact-heading m-0">
              Utility & Quick Actions 
              </p>
              <hr className="m-0 my-2 bg_color" style={{padding:'1px'}} />
            </div>
            <div className="d-flex justify-content-start flex-wrap mx-auto px-md-1">
              <button
                onClick={() => {
                  setProblem(true);
                }}
                className="btn-order m-1 my-2 shadow-sm "
              >
                Add Problem
              </button>
              <button
                onClick={() => setAssesment(true)}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Add Assessments
              </button>
              <button
                onClick={() => setOrder(true)}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Add Order
              </button>
              <button
                onClick={() => setRecommend(true)}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Recommend Appointment
              </button>
              <button
                onClick={() => gotoPay()}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Create Payment Link
              </button>
              <button
                onClick={() => setGoal(true)}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Add Goal
              </button>
              <button
                onClick={() => setExercise(true)}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Add Exercise
              </button>
              <button
                onClick={() => setRemind(true)}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Remind Assesment
              </button>
              <button
                onClick={() => setSlots(true)}
                className="btn-order m-1 my-2 shadow-sm"
              >
                Send Slots
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center align-content-center Profile">
          <p className="py-md-5 my-md-5 header_font _text_color h6">Open a chat to view user details</p>
        </div>
      )}
    </>
  );
};

export default Profile;
