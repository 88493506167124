import React, {useEffect} from 'react'
import { useParams, useHistory } from 'react-router-dom'

const RedirectChat = () => {
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        history.push("/panel/conversations",{data:{'id':id}});
    }, [])
    console.log(id)
    return (
        <div>
            
        </div>
    )
}

export default RedirectChat
