import React,{createRef} from "react";
import { XCircle, Send } from 'react-feather'
import ScrollableFeed from "react-scrollable-feed";



class ReceiveMsg extends React.Component {
    constructor(props) {
        super(props);
        this.messagesEndRef = React.createRef()
        this.state={
            prv:''
        }
    }
    // componentDidMount(){
    //     console.log('news',this.props)
    //     this.scrollToBottom()
    // }
    // shouldComponentUpdate(nextProps, nextState) {
        // if(this.state.dropdownVal != nextState.dropdownval) {
        //      return false
        // }
        // return true
    // }   
    // componentDidUpdate(){
    //     this.scrollToBottom()
    // }
    componentDidUpdate(){
        // this.scrollToBottom()
    }
      componentWillReceiveProps(prevProps, newProps){
        // console.log('recs', prevProps, newProps)
        if(prevProps !== newProps){
            // this.scrollToBottom()

        }
      }
      scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: 'auto' })
      }  
    render() {
        let newMsg = this.props?.newMsg
        let link=this.props?.link
        return (
            <>
               {/* <ScrollableFeed>  */}
                {newMsg !== "" && newMsg !== null ?
                <div style={{whiteSpace:'pre-wrap'}} ref={this.messagesEndRef} className="message-show ml-auto">
                 {link?
                      <a className="message_s_p" target="_blank" href={newMsg.split('.')[0].trim()=='www'?'https://'+newMsg:newMsg}>{newMsg}</a> : 
                        <p className = "message_r_p tex_p">
                            {newMsg}
                        </p>}
                   
                    </div> 
                    : ''}
                    {/* </ScrollableFeed> */}
                    
            </>
        )
    }
}

export default ReceiveMsg;
