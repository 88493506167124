import React,{useEffect} from 'react'
import Chat from './Chat';
import Chatting from './Chatting';
import Profile from './Profile';
import '../CSS/Chat.css'
import { useState } from 'react';
import Message from './messages';
import axios from "axios";
import { BASEURL, AGORA_APP_ID,headers } from "./utils/constant";
import { withRouter } from 'react-router-dom';

const Conversation = (props) => {
    const [data, setData] = useState('')
    const [session, setSession]=useState()
    const [pat,setPat]=useState()
    const [stat, setStat]=useState()
    const [showProfile, setShowProfile] = useState(false);

    const passData = (data1) =>{
        console.log('sad',data1);
        setData(data1)
        setSession('')
        setPat('')
        getSes(data1.channelid,data1.patient_n_key)
        localStorage.channelid=data1.channelid
        localStorage.patient_n_key=data1.patient_n_key
      }

     
      const getSes=(id1, id2)=>{ 
        axios.post(BASEURL + "api/chat//",
         {
           channelid:id1,
           id:id2,
           
         },
      
         {
           headers: {
             "Content-Type": "application/json",
             Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
           },
         }
       )
       .then((response) => {
         if(response.data.status=='success'){
           setSession(response.data.session)
           setPat(response.data.patent[0])
         }
         console.log(response)
         
        }
       )
      }

      useEffect(()=>{
        if(props?.location?.state?.data?.uhid && props?.location?.state?.data?.pat_key){
         let dat = {channelid:props?.location?.state?.data?.uhid,patient_n_key:props?.location?.state?.data?.pat_key}
         getSes(props?.location?.state?.data?.uhid,props?.location?.state?.data?.pat_key)
         setData(dat)
      }
    },[props])

    const openUserProfile = () => {
      setShowProfile(true);
    };
   

        return (
         
           
          <div className="d-flex flex-column flex-md-row col-md-12 p-0 m-0" style={{maxHeight:'100vh',width:'auto',overflowX:'hidden'}}>
              
            <Chat PassData={passData}  />
           
            <Chatting user={data&&data} openUserProfile={openUserProfile} />
           
           <Profile users={data&&data} burgerMenuVisible={showProfile} session={session&&session} pat={pat&&pat}/>
            </div>
         
        );
}

export default withRouter(Conversation); 
