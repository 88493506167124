import React from "react";
import axios from "axios";
import { useState, useMemo } from "react";
import { useEffect } from "react";
import Profile from "./Profile";
import Channel from "./Agora/Channel";
import { BASEURL, headers } from "./utils/constant";
import { GlobeOutline, SearchOutline } from "react-ionicons";
import ReactTimeAgo from "react-time-ago";
import Badge from "@material-ui/core/Badge";
import { useHistory, useLocation } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { DocumentTextOutline } from "react-ionicons";
import { AiOutlineFrown } from "react-icons/ai";
import useSound from "use-sound";
import notificationSound from "../assets/images/alert.mp3";
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDown, ArrowUp } from "react-feather";

const useStyles = makeStyles(theme => ({
  badge: {
    backgroundColor: '#f5ab2c', 
    color: '#fff', 
  },
}));
var colors = {
  g: "#FF6633",
  v: "#00ff00",
  a: "#34d5eb",
};
var color;
const clr = "#000";
const Chat = (props) => {
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(-1);
  const [empty, setEmpty] = useState(false);
  const [play] = useSound(notificationSound);
  const [page,setPage]=useState(0);
  const [totalPage, setTotalPage]=useState(0);
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const filteredUsers = useMemo(() => {
    if (search) {
      return user.filter(
        (item) =>
          item.first_name.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
    } else {
      return 0;
    }
  }, [search]);
  const getRandomColor = (val) => {

    if (val === "A") {
      color = "#FF6633";
    } else if (val === "B") {
      color = "#FF33FF";
    } else if (val === "C") {
      color = "#00B3E6";
    } else if (val === "D") {
      color = "#E6B333";
    } else if (val === "E") {
      color = "#80B300";
    } else if (val === "F") {
      color = "#66994D";
    } else if (val === "G") {
      color = "#B3B31A";
    } else if (val === "H") {
      color = "#E666B3";
    } else if (val === "I") {
      color = "#4D8066";
    } else if (val === "J") {
      color = "#FF3380";
    } else if (val === "K") {
      color = "#E64D66";
    } else if (val === "L") {
      color = "#3366E6";
    } else if (val === "M") {
      color = "#809900";
    } else if (val === "N") {
      color = "#B366CC";
    } else if (val === "O") {
      color = "#991AFF";
    } else if (val === "P") {
      color = "#33991A";
    } else if (val === "Q") {
      color = "#CCCC00";
    } else if (val === "R") {
      color = "#FF4D4D";
    } else if (val === "S") {
      color = "#E666FF";
    } else if (val === "T") {
      color = "#4D8000";
    } else if (val === "U") {
      color = "#FF1A66";
    } else if (val === "V") {
      color = "#6680B3";
    } else if (val === "W") {
      color = "#9900B3";
    } else if (val === "X") {
      color = "#4D80CC";
    } else if (val === "Y") {
      color = "#CC80CC";
    } else {
      color = "#66664D";
    }

    // }
    return color;
  };
const removeDuplicatesFromDetails=(array)=> {
  const uniqueObjects = [];
  const seenObjects = new Set();
  array.forEach(obj => {
    const objectString = JSON.stringify(obj);
    if (!seenObjects.has(objectString)) {
      seenObjects.add(objectString);
      uniqueObjects.push(obj);
    }
  });
  return uniqueObjects;
  }
  useEffect(() => {
    localStorage.employee_n_key = location?.state?.data?.id;
    localStorage.p__=1
    const interval = setInterval(() => {
     getChat()
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  useEffect(()=>{
    localStorage.pp__ = page
  },[page])
const getChat=async()=>{
  if (location?.state) {
    const data = {
      org_n_key: "ORGID-1",
      employee_n_key: location?.state?.data?.id,
      type_of_chat:null,
      page:parseInt(localStorage.p__)||1,
    };
    axios
      .post(BASEURL + "api/chat/list_chat/", data, { headers:{'Authorization':'Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf'}})
      .then((response) => {
        setPage(response?.data?.current_page);
        const pValue = parseInt(localStorage.p__);
        const ppValue = parseInt(localStorage.pp__);
        if (!isNaN(pValue)) {
          setUser((prev) => {
            if (pValue === 1) {
              return response.data.data; 
            } 
            else if(pValue===ppValue){
              return [...prev]
            }
            else {
              const mergedData = [...prev, ...response.data.data];
              return removeDuplicatesFromDetails(mergedData);
            }
          });
         
        } else {
          console.error("localStorage.p__ is not a valid integer.");
        }
        setTotalPage(response.data.total_pages)
        setLoading(false);
        if (response.data.data.length < 1) {
          setEmpty(true);
        }
      });
  } else {
    history.push("/");
  }
}
const addpageKey=()=>{
  setLoading(true)
  let dat = parseInt(localStorage.p__)||1
  localStorage.p__ = dat+1
}
const removePage=()=>{
  setLoading(true)
  localStorage.p__ = 1
}

  return (
    <div
      className="col-md-3 p-1 bg_secondary"
      style={{ borderRight: "1px solid #215352",overflowY:'scroll',maxHeight:'100%'}}
    >
      <div className="p-2">
        <div
          id="search-wrapper"
          className="top-wrapper bg-white p-2 rounded mt-md-4 shadow-sm"
        >
          <input
            type="search"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="search-input"
            placeholder="Search here.."
          />
          <p className="text-center">
            <SearchOutline color={"#00000"} height="20px" width="20px" />
          </p>
        </div>
        {empty && (
          <div className="loader-center">
            <AiOutlineFrown style={{ fontSize: "62px" }} />

            <p>
              All caught up <br />
              No chats to look into for now. Take a breather!
            </p>
          </div>
        )}
        {loading && (
          <div className="loader-center _text_color">
            <p>
              Hold on... <br />
              we are fetcthing your chats{" "}
            </p>
            <PuffLoader color="#215352" loading={loading} size={120} />
          </div>
        )}
        {filteredUsers.length > 0 ? (
          <ul className="visitor-list mt-md-3">
            {filteredUsers &&
              filteredUsers.map((users, index) => {
                return (
                  <li
                    className={current === index && "active"}
                    key={index}
                    onClick={() => {
                      props.PassData(users);
                      setCurrent(index);
                    }}
                  >
                    <a>
                      <div
                        role="button"
                        tabIndex="-1"
                        className="avatar"
                        style={{
                          background: getRandomColor(
                            users.first_name.slice(0, 1).toUpperCase()
                          ),
                        }}
                      >
                        {users.first_name.slice(0, 1).toUpperCase()}
                      </div>
                      <div className="name-section">
                        <div className="tex_p _text_color">
                          <span>
                            {users.last_name === null
                              ? users.first_name
                              : users.first_name + " " + users.last_name}
                          </span>
                          {/* <span>{user.messages[0].message}</span> */}
                          <div className="date">
                            {users.messages != null ? (
                              <ReactTimeAgo
                                date={
                                  users.messages[users.messages.length - 1].time
                                }
                                locale="en-US"
                                timeStyle="twitter-minute"
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="last-text">
                          <span>
                            {users.messages != null ? (
                              <div>
                                <span>
                                  {users.messages[users.messages.length - 1]
                                    .msg_type === "File" ? (
                                    <div>
                                      <DocumentTextOutline
                                        color={"#00000"}
                                        height="15px"
                                        width="15px"
                                      />{" "}
                                      Attachments
                                    </div>
                                  ) : users.messages[users.messages.length - 1]
                                      .msg_type === "Slots" ? (
                                    <div>Available Slots</div>
                                  ) : users.messages[users.messages.length - 1]
                                      .message.length > 32 ? (
                                    <div>
                                      {" "}
                                      {users.messages[
                                        users.messages.length - 1
                                      ].message.slice(0, 32)}{" "}
                                      ...
                                    </div>
                                  ) : (
                                    users.messages[users.messages.length - 1]
                                      .message
                                  )}
                                </span>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </span>

                          <span>
                            <Badge
                              badgeContent={users.unseen_count}
                              color="#f2b64e"
                            />
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })}
          </ul>
        ) : (
          <ul className="visitor-list mt-md-3">
            {user.length > 0 ? (
              user &&
              user.map((users, index) => {
                return (
                  <li
                    className={current === index && "active"}
                    key={index}
                    onClick={() => {
                      props.PassData(users);
                      setCurrent(index);
                    }}
                  >
                    <a>
                      <div
                        role="button"
                        tabIndex="-1"
                        className="avatar" 
                        style={{
                          background: getRandomColor(
                            users.first_name.slice(0, 1).toUpperCase()
                          ),
                        }}
                      >
                        {users.first_name.slice(0, 1).toUpperCase()}
                      </div>
                      <div className="name-section">
                        <div className="d-flex justify-content-between ">
                          <span className="header_font _text_color" style={{fontWeight:'bold',fontSize:'15px',maxWidth:'180px'}}>
                            {users.last_name === null
                              ? users.first_name
                              : users.first_name + " " + users.last_name}
                          </span>
                          <div className="date tex_p _text_color">
                            {users.messages != null ? (
                              <ReactTimeAgo
                                date={
                                  users.messages[users.messages.length - 1].time
                                }
                                locale="en-US"
                                timeStyle="twitter-minute"
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>
                            {users.messages != null ? (
                              <div className="_text_color tex_p">
                                <span>
                                  {users.messages[users.messages.length - 1]
                                    .msg_type === "File" ? (
                                    <div>
                                      <DocumentTextOutline
                                        color={"#00000"}
                                        height="15px"
                                        width="15px"
                                      />{" "}
                                      Attachments
                                    </div>
                                  ) : users.messages[users.messages.length - 1]
                                      .msg_type === "Slots" ? (
                                    <div>Available Slots</div>
                                  ) : users.messages[users.messages.length - 1]
                                      .message.length > 32 ? (
                                    <div>
                                      {" "}
                                      {users.messages[
                                        users.messages.length - 1
                                      ].message.slice(0, 32)}{" "}
                                      ...
                                    </div>
                                  ) : (
                                    users.messages[users.messages.length - 1]
                                      .message
                                  )}
                                </span>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </span>
                          <span>
                            <Badge
                              badgeContent={users.unseen_count}
                              classes={{ badge: classes.badge }}
                            />
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })
            ) : null}
          </ul>
        )}
       {totalPage>1 && <button onClick={()=>{totalPage===page?removePage():addpageKey()}} style={{backgroundColor:'#ddefe5',color:'#215352',border:'1px solid #215352'}} className="mx-auto fw-bold rounded col-md-12 px-3 py-2 text-center">
       {totalPage===page?'See Less':'Load more conversations'}
        {totalPage===page?<ArrowUp color={"#215352"} height="23px" width="23px" className="pl-3" />: <ArrowDown color={"#215352"} height="23px" width="23px" className="pl-3" />}
        </button>}
      </div>
    </div>
  );
};

export default Chat;
