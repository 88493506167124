import React from 'react'
import errorpage from '../assets/images/404.png'


const Error = () => {
    return (
        <div>
             <section class="page_404">
        <div class="container">
            <div class="row">	
            <div class="col-sm-12 ">
            <div class="col-sm-10 mx-auto  text-center">
            <div class="four_zero_four_bg">

              <img src={errorpage} />       
            </div>
            
            <div class="contant_box_404">
            <h3 class="h2">
            Look like you're lost
            </h3>
            
            <p>The page you are looking for not available!</p>
            
        </div>
            </div>
            </div>
            </div>
        </div>
    </section>
        </div>
    )
}

export default Error

