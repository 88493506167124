import React, { useEffect, useState } from "react";
import {
  ReturnUpBackOutline
} from "react-ionicons";
import { useLocation } from "react-router-dom";
import Messages from "./messages";
import { BASEURL } from "./utils/constant";

const Chatting = (props) => {
  const user = props.user;
  const location = useLocation();
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 200);
  }, [props]);



  return (
    <div
      // d-none d-md-block
      className="col-md-6"
      style={{ overflowY: "scroll", maxHeight: "100%" }}
    >
      {user ? (
        <div className="conversation">
          <div className="conversation-header">
            <div className="py-md-3">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `${BASEURL}calendar`;
                }}
                type="button"
                className="chat-back-button"
              >
                <ReturnUpBackOutline
                  color={"#00000"}
                  height="25px"
                  width="25px"
                />
                <span>Back to Calendar</span>
              </button>
            </div>
          </div>
          <div>
            {show?null:<Messages data={user} empKey={location.state.data.id} />}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <p className="header_font _text_color h5">
            Open a chat to start the conversation
          </p>
          <div className="my-md-3">
            <button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `${BASEURL}calendar`;
              }}
              type="button"
              className="chat-back-button"
            >
              <ReturnUpBackOutline
                color={"#00000"}
                height="25px"
                width="25px"
              />
              <span>Back to Calendar</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatting;
