import logo from "./logo.svg";
import "./App.css";
import Chat from "./Components/Chat";
import Chatting from "./Components/Chatting";
import Profile from "./Components/Profile";
import "./CSS/Chat.css";
import { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Message from "./Components/messages";
import Conversation from "./Components/Conversation";
import Error from "./Components/Error";
import RedirectChat from "./Components/RedirectChat";
import Getchat from "./Components/Getchat";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route  path="/panel/conversations" component={Conversation} />
          <Route exact path="/:id/:id/:id/conversations/" component={Getchat} />
          {/* <Route path="/getchat/:id/:id" component={RedirectChat}/> */}
          <Route path="/getchat/:id" component={RedirectChat}/>
          <Route exact path="/" component={Error} />
          <Route exact path="*" component={Error} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
