import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { BASEURL } from "./utils/constant";
import axios from "axios";

const Getchat = (props) => {
  const history = useHistory();

  useEffect(() => {
    console.log(window.location.pathname);
    var path = window.location.pathname;
    var data = {};
    data.id = path.split("/")[1];
    data.uhid = path.split("/")[2];
    data.pat_key = path.split("/")[3];
    console.log(data);
    localStorage.channelid = data.uhid;
    localStorage.patient_n_key = data.pat_key;

    axios
      .post(
        BASEURL + "api/chat/view_chat/",
        {
          channelid: path.split("/")[2],
          id: path.split("/")[3],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
        }
      )
      .then((res) => {
        console.log(res)
        if (res.data.status == "success") {
          if (res.data.chat_history.length == 0) {
            axios.post(
              BASEURL + "api/caremeapp/counselling_room/",
              {
                channelid: path.split("/")[2],
                patient_n_key: path.split("/")[3],
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
              }
            ) .then((ress) => {
              history.push('/panel/conversations',{data:data})
              console.log("new", ress);
            });
          }
          else{
            history.push('/panel/conversations',{data:data})
          }
        }
      })
  }, null);

  useEffect(() => {
    const path = window.location.pathname;
  }, null);
  return <div></div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(Getchat);
